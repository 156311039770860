@import url(//fonts.googleapis.com/css?family=Barlow);
@import url(//fonts.googleapis.com/css?family=Ubuntu);

body {
  background-color: #EEE;
  margin: 0;
}

.Typist .Cursor {
  display: inline-block;
}

.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}